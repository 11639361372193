import { Component } from "react";

class Activity extends Component {
    componentDidMount() {
        window.location.href = "https://photos.app.goo.gl/B5qMKqBRbVQtQuJH9";
    }
    render() {
        return true;
    }
}

export default Activity;
